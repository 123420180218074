import { useState, useEffect } from "react";
import { Dropdown, DatePicker, ComboBox } from "@fluentui/react";
import { Button } from "@fluentui/react-components";
import { appInfo } from "../../authConfig";
import Fuse from "fuse.js";
import i18next from "i18next";
interface Props {
    corpIdList?: any;
    usersList?: any;
    handleApplyFilterClick: (filters: object) => void;
    resetFilters: (options: object) => void;
    applyFrontFilter: (values: object) => void;
    updateUserList: (corp_id: string, isFilterApplied: boolean) => void;
}

export const Filters = ({ corpIdList, usersList, handleApplyFilterClick, applyFrontFilter, updateUserList, resetFilters }: Props) => {
    const [selectedFeedType, setSelectedFeedType] = useState<any | null>(null);
    const [selectedUsers, setSelectedUsers] = useState<any | null>(null);
    const [selectedCorpId, setSelectedCorpId] = useState<any | null>(null);
    const [chatModel, setChatModel] = useState<any | null>(null);
    const [appType, setAppType] = useState<any | null>(null);
    const [retrievalMode, setRetrievalMode] = useState<any | null>(null);
    const [semanticFlag, setSemanticFlag] = useState<any | null>(null);
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const [isPrimaryFiltersChanged, setIsPrimaryFiltersChanged] = useState<boolean>(false);
    const [isPrimaryFiltersApplied, setIsPrimaryFiltersApplied] = useState<boolean>(false);
    const [isFiltersChanged, setIsFiltersChanged] = useState<boolean>(false);
    const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
    const [isCorpChanged, setIsCorpChanged] = useState<boolean>(false);
    const createDropdownOptions = (data: any) => Object.keys(data).map((key: string) => ({ key: key.toLowerCase(), text: data[key] }));

    const appTypeOptions = createDropdownOptions(appInfo.app_type);
    const feedbackTypeOptions = createDropdownOptions(appInfo.feedbacktype);
    const semanticOptions = createDropdownOptions(appInfo.semantic);
    const aiModelOptions = createDropdownOptions(appInfo.aimodel_admin);
    const retrievalOptions = createDropdownOptions(appInfo.retrieval_mode);

    const [userListOptions, setUserListOptions] = useState<any>([]);
    const [corpListOptions, setCorpListOptions] = useState<any>([]);

    const resetFields = () => {
        setIsPrimaryFiltersApplied(false);
        resetFilters({ type: "all" });
        setIsFiltersChanged(false);
        setIsCorpChanged(false);
        setIsPrimaryFiltersChanged(false);
        setSelectedFeedType(null);
        setSelectedUsers(null);
        setSelectedCorpId(null);
        setChatModel(null);
        setAppType(null);
        setRetrievalMode(null);
        setSemanticFlag(null);
        setStartDate(undefined);
        setEndDate(undefined);
        setIsFilterApplied(false);
    };

    const applyFilter = () => {
        const filters = {
            selectedCorpId,
            selectedUsers,
            appType,
            selectedFeedType,
            startDate: startDate?.toLocaleDateString("en-US"),
            endDate: endDate?.toLocaleDateString("en-US"),
            chatModel,
            retrievalMode,
            semanticFlag
        };

        if (Object.values(filters).some(value => value !== null && value !== undefined)) {
            if (!isFilterApplied || isPrimaryFiltersChanged || (!isPrimaryFiltersApplied && isFiltersChanged && isCorpChanged)) {
                handleApplyFilterClick({
                    filters: filters,
                    isPrimaryFiltersChanged
                });
            } else {
                applyFrontFilter({
                    selectedCorpId,
                    selectedUsers
                });
            }
            setIsPrimaryFiltersChanged(false);
            setIsCorpChanged(false);
            setIsFilterApplied(true);
        }
    };

    const updateCorpIds = (user_id: string | number | null) => {
        const corpUsers = usersList
            ?.filter((item: any) => item.key === user_id)
            .map((value: any) => {
                return { key: value["corp_id"], text: value["corp_id"] };
            });
        setSelectedCorpId(corpUsers[0] ?? null);
    };

    const handleComboChange = (type: string, item: any) => {
        switch (type) {
            case "corpIdList":
                if (!item) {
                    setSelectedCorpId(null);
                    return;
                }
                if (item.key === "clear") {
                    resetFilters({ type: "corps" });
                    setSelectedCorpId(null);
                    setSelectedUsers(null);
                } else {
                    setSelectedCorpId(item ?? null);
                    updateUserList(item?.key ?? null, !isPrimaryFiltersApplied && true);
                    setSelectedUsers(null);
                    setIsCorpChanged(true);
                }
                break;
            case "usersList":
                if (!item) {
                    setSelectedUsers(null);
                    return;
                }
                if (item.key === "clear") {
                    resetFilters({ type: "user" });
                    setSelectedUsers(null);
                    setSelectedCorpId(null);
                } else {
                    updateCorpIds(item?.key ?? null);
                    setSelectedUsers(item ?? null);
                }
                break;
            default:
                break;
        }
        setIsFiltersChanged(true);
    };

    const handleFilterChange = (type: string, value: any) => {
        switch (type) {
            case "feedbackType":
                setSelectedFeedType(value);
                break;
            case "startDate":
                setStartDate(value);
                break;
            case "endDate":
                setEndDate(value);
                break;
            case "chatModel":
                setChatModel(value);
                break;
            case "appType":
                setAppType(value);
                break;
            case "retrievalMode":
                setRetrievalMode(value);
                break;
            case "semanticFlag":
                setSemanticFlag(value);
                break;
            default:
                break;
        }
        setIsPrimaryFiltersChanged(true);
        setIsPrimaryFiltersApplied(true);
    };

    const buttonStyles = {
        margin: "1px",
        borderRadius: "10px",
        border: "1px solid",
        backgroundColor: "rgb(0, 120, 212)",
        color: "white",
        height: "34px",
        padding: "0px 7px",
        opacity: !isPrimaryFiltersApplied && !isFiltersChanged ? "0.5" : "1"
    };

    const handleSearch = (initialData: any, value: string, setState: any) => {
        if (value.length === 0) {
            setState(initialData);
            return;
        }
        const options = {
            includeScore: true,
            includeMatches: true,
            ignoreLocation: true,
            threshold: 0.6,
            keys: ["text"]
        };

        const fuse = new Fuse(initialData, options);

        const results = fuse.search(value);
        const items = results.map(result => result.item);
        setState(items);
    };

    useEffect(() => {
        setUserListOptions(usersList);
    }, [usersList]);

    useEffect(() => {
        setCorpListOptions(corpIdList);
    }, [corpIdList]);

    return (
        <div style={{ display: "flex", flexWrap: "wrap", padding: "5px" }}>
            <DatePicker
                className="startDate"
                style={{ width: "200px", margin: "2px" }}
                placeholder={i18next.t("filters.startDate")}
                formatDate={date => (date ? date.toLocaleDateString() : "")}
                onSelectDate={date => handleFilterChange("startDate", date || undefined)}
                value={startDate}
                maxDate={new Date()}
            />
            <DatePicker
                className="endDate"
                style={{ width: "200px", margin: "2px" }}
                placeholder={i18next.t("filters.endDate")}
                formatDate={date => (date ? date.toLocaleDateString() : "")}
                onSelectDate={date => handleFilterChange("endDate", date || undefined)}
                value={endDate}
                minDate={startDate}
                maxDate={new Date()}
            />
            <ComboBox
                className="corpIdList"
                style={{ minWidth: "140px", margin: "2px" }}
                selectedKey={selectedCorpId?.key || null}
                placeholder={i18next.t("filters.corpId")}
                allowFreeform
                autoComplete="on"
                options={corpListOptions}
                onChange={(event, item: any) => handleComboChange("corpIdList", item)}
                onInputValueChange={text => handleSearch(corpIdList, text, setCorpListOptions)}
            />
            <ComboBox
                className="usersList"
                style={{ minWidth: "200px", margin: "2px" }}
                selectedKey={selectedUsers?.key || null}
                placeholder={i18next.t("filters.userId")}
                allowFreeform
                autoComplete="on"
                options={userListOptions}
                onChange={(event, item) => handleComboChange("usersList", item)}
                onInputValueChange={text => handleSearch(usersList, text, setUserListOptions)}
            />
            <Dropdown
                className="appType"
                style={{ width: "140px", margin: "2px" }}
                selectedKey={appType?.key || null}
                onChange={(event, item) => handleFilterChange("appType", item)}
                placeholder={i18next.t("filters.apptype")}
                options={appTypeOptions}
            />
            <Dropdown
                className="feedbackType"
                style={{ width: "140px", margin: "2px" }}
                selectedKey={selectedFeedType?.key || null}
                onChange={(event, item) => handleFilterChange("feedbackType", item)}
                placeholder={i18next.t("filters.feedbackType")}
                options={feedbackTypeOptions}
            />
            <Dropdown
                className="Model"
                style={{ width: "170px", margin: "2px" }}
                selectedKey={chatModel?.key || null}
                placeholder={i18next.t("filters.openAIModel")}
                options={aiModelOptions}
                onChange={(event, item) => handleFilterChange("chatModel", item)}
            />
            <Dropdown
                className="Retrieval"
                style={{ width: "190px", margin: "2px" }}
                selectedKey={retrievalMode?.key || null}
                placeholder={i18next.t("filters.retrivalMode")}
                options={retrievalOptions}
                onChange={(event, item) => handleFilterChange("retrievalMode", item)}
            />
            <Dropdown
                className="Semantic"
                style={{ width: "160px", margin: "2px" }}
                selectedKey={semanticFlag?.key || null}
                placeholder={i18next.t("filters.semanticRanker")}
                options={semanticOptions}
                onChange={(event, item) => handleFilterChange("semanticFlag", item)}
            />

            <div style={{ display: "flex" }}>
                <Button
                    size="large"
                    className="filterButton"
                    style={buttonStyles}
                    title={i18next.t("filters.applyFilterInfo")}
                    onClick={applyFilter}
                    disabled={!isPrimaryFiltersApplied && !isFiltersChanged}
                >
                    {i18next.t("filters.applyFilter")}
                </Button>
                <Button
                    size="large"
                    className="clearButton"
                    style={buttonStyles}
                    title={i18next.t("filters.resetFilterInfo")}
                    onClick={resetFields}
                    disabled={!isPrimaryFiltersApplied && !isFiltersChanged}
                >
                    {i18next.t("filters.resetFilters")}
                </Button>
            </div>
        </div>
    );
};
