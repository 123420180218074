import { useEffect, useState } from "react";
import styles from "../updates/Update.module.css";
import i18next from "../../i18n/i18n";
import wap from "../../assets/wap.svg";
import AIWorks_T_C from "../../assets/AIWORKS_T&C.pdf";
import { useNavigate } from "react-router-dom";

const Terms = () => {
    const navigate = useNavigate();
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div style={{ display: "flex" }}>
            <div>
                <header className={styles.header} role={"banner"}>
                    <div className={styles.headerContainer}>
                        <div
                            className={styles.headerTitleContainer}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                if (
                                    window.location.pathname !== "/assistant/chat" ||
                                    (window.location.pathname === "/assistant/chat" && window.location.search !== "")
                                ) {
                                    navigate("/assistant/*", { replace: true });
                                }
                            }}
                        >
                            <img src={wap} alt={i18next.t("layout.wap")} aria-label={i18next.t("layout.wap")} style={{ marginRight: "20px" }} />
                            <h2 className={styles.headerTitle}>{i18next.t("layout.headerTitleOne")}</h2>
                            <h5 className={styles.headerTitle} style={{ marginTop: "0px", marginLeft: "7px" }}>
                                {i18next.t("layout.headerTitleTwo")}
                            </h5>
                        </div>
                    </div>
                </header>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "52px"
                }}
            >
                <embed id="pdf-embed" src={AIWorks_T_C} type="application/pdf" width={`${window.innerWidth}`} height={`${window.innerHeight - 63}`}></embed>
            </div>
        </div>
    );
};

export default Terms;
