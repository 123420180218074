import { createSlice } from "@reduxjs/toolkit";

interface QuestionInputState {
    templateAppliedFlag: boolean;
}

const questionInputInitialState: QuestionInputState = {
    templateAppliedFlag: false
};

const QuestionInputReducer = createSlice({
    name: "questionInput",
    initialState: questionInputInitialState,
    reducers: {
        updateTemplateAppliedFlag: (state, actions) => {
            state.templateAppliedFlag = actions.payload;
        }
    }
});

export const QuestionInputReducerAction = QuestionInputReducer.actions;

export default QuestionInputReducer.reducer;
