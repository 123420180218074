import { configureStore } from "@reduxjs/toolkit";
import TemplateContents from "./TemplateContents";
import QuestionInputReducer from "./QuestionInputReducer";

const store = configureStore({
    reducer: {
        templateContents: TemplateContents,
        questionInput: QuestionInputReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
