import { useState, useEffect } from "react";
import { Text, DefaultButton, Link, Pivot, PivotItem } from "@fluentui/react";
import styles from "./SupportingContent.module.css";
import { getCitationFilePath, DataPoints } from "../../api";
import { Label } from "@fluentui/react-components";
import i18next from "../../i18n/i18n";
import { userInfo } from "../../authConfig";
import { AnalysisPanelTabs } from "../AnalysisPanel/AnalysisPanelTabs";
import { Divider } from "@mui/material";
interface Props {
    supportingContent: [DataPoints] | [];
    index: number;
    onActiveContentChanged: (citationIndex: number) => void;
    appType: string | null;
    sourcePage: string;
    openView: any;
    sanitizedThoughts?: any;
}

export const SupportingContent = ({ supportingContent, index, onActiveContentChanged, appType, sourcePage, openView, sanitizedThoughts }: Props) => {
    const [activeTab, setActiveTab] = useState<string | undefined>(index.toString());
    const [selectedContent, setSelectedContent] = useState<string | undefined>();
    const commonStyle: any = {
        overflowX: "auto",
        borderRadius: "10px",
        display: "flex",
        outline: "2px solid rgba(115, 118, 225, 1)",
        maxHeight: sourcePage === "Feedback" ? window.innerHeight - 240 : window.innerHeight - 140,
        maxWidth: window.innerWidth - 250
    };
    useEffect(() => {
        setActiveTab(index.toString());
        setSelectedContent(supportingContent[index > 0 ? index - 1 : index]?.content_field || "");
    }, [supportingContent, index]);
    if (openView === AnalysisPanelTabs.ThoughtProcessTab) {
        return (
            <ul className={styles.supportingContentNavList}>
                <div className={styles.supportingContentItem} style={commonStyle}>
                    <div className={styles.thoughtProcess} dangerouslySetInnerHTML={{ __html: sanitizedThoughts }}></div>
                </div>
            </ul>
        );
    } else if (openView === AnalysisPanelTabs.SupportingContentTab) {
        return (
            <>
                {/* <Text>{i18next.t("supportingContent.citationInfo")}</Text> */}
                <ul className={styles.supportingContentNavList}>
                    <div className={styles.supportingContentItem} style={commonStyle}>
                        <Pivot
                            selectedKey={activeTab}
                            overflowBehavior="menu"
                            onLinkClick={(pivotItem: any) => {
                                setActiveTab(pivotItem?.props.itemKey ?? undefined);
                                onActiveContentChanged(parseInt(pivotItem?.props.itemKey ?? "1"));
                                setSelectedContent(supportingContent[pivotItem?.props.itemKey - 1 ?? "0"]?.content_field || "");
                            }}
                        >
                            {supportingContent?.map((x, i) => {
                                return (
                                    <PivotItem
                                        key={i}
                                        headerText={(i + 1).toString()}
                                        itemKey={(i + 1).toString()}
                                        itemIcon={x.doctype === "Internal" ? "LockSolid" : undefined}
                                    >
                                        <Divider sx={{ width: "99%", margin: "auto" }} />

                                        <div style={{ textOverflow: "ellipsis" }}>
                                            <Text className={styles.supportingContentCaption} style={{ paddingRight: "15px" }}>
                                                <b>{i18next.t("supportingContent.citationSource")} :</b>
                                            </Text>
                                            <Link
                                                className={styles.supportingContentCaption}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    const upload_dir = x.uploaddir_field + "/" + x.sourcepage_field;
                                                    const directory =
                                                        appType === "Manuals"
                                                            ? "product_manual/" + x.sourcepage_field
                                                            : upload_dir.replace(/^[^/]*\//, appType === "Manuals" ? "product_manual/" : "faq/");
                                                    const path = getCitationFilePath(directory);
                                                    window.open(path, "_blank");
                                                }}
                                                title={i18next.t("supportingContent.openinNewTab")}
                                            >
                                                {(x.uploaddir_field + "/" + x.sourcepage_field).replace(
                                                    /^[^/]*\//,
                                                    appType === "Manuals" ? "product_manual/" : "faq/"
                                                )}
                                            </Link>
                                        </div>
                                        <div style={{ textOverflow: "ellipsis" }}>
                                            <Text className={styles.supportingContentCaption} style={{ paddingRight: "15px" }}>
                                                <b>{i18next.t("supportingContent.originalSource")} :</b>
                                            </Text>
                                            <Link
                                                className={styles.supportingContentCaption}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => window.open(x.doc_url, "_blank")}
                                                title={i18next.t("supportingContent.openinNewTab")}
                                            >
                                                {x.doc_url}
                                            </Link>
                                        </div>
                                        {x.hasPassword && (
                                            <div style={{ textOverflow: "ellipsis" }}>
                                                <Text className={styles.supportingContentCaption} style={{ paddingRight: "15px", color: "red" }}>
                                                    <b>{i18next.t("supportingContent.password")} :</b>
                                                </Text>
                                                <Link
                                                    className={styles.supportingContentCaption}
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => window.open("https://support.worksap.co.jp/downloadShowPassword.do", "_blank")}
                                                    title={i18next.t("supportingContent.openinNewTab")}
                                                >
                                                    {"https://support.worksap.co.jp/downloadShowPassword.do"}
                                                </Link>
                                            </div>
                                        )}
                                        {(userInfo.isWap === "true" || userInfo.isWap == "True") && (
                                            <div style={{ display: "flex" }}>
                                                <div style={{ position: "relative", width: "100%" }}>
                                                    <Text className={styles.supportingContentCaption} style={{ paddingRight: "15px" }}>
                                                        <b>{i18next.t("supportingContent.score")} :</b> {x.search_score}
                                                    </Text>
                                                    <Text className={styles.supportingContentCaption} style={{ paddingRight: "15px" }}>
                                                        <b>{i18next.t("supportingContent.rerankerScore")} : </b>
                                                        {x.search_reranker_score}
                                                    </Text>
                                                    <br />
                                                    <Text className={styles.supportingContentCaption} style={{ paddingRight: "15px" }}>
                                                        <b style={{ color: "inherit" }}>{i18next.t("chat.documenttype")} : </b>
                                                        <span style={{ color: x.doctype === "Internal" ? "red" : "inherit", fontWeight: "bold" }}>
                                                            {i18next.t(`chat.${x.doctype}`)}
                                                        </span>
                                                    </Text>
                                                </div>
                                                <div className="window_button">
                                                    <DefaultButton
                                                        className={styles.openNewWindowButton}
                                                        onClick={() => {
                                                            const upload_dir = x.uploaddir_field + "/" + x.sourcepage_field;
                                                            const directory =
                                                                appType === "Manuals"
                                                                    ? "product_manual/" + x.sourcepage_field
                                                                    : upload_dir.replace(/^[^/]*\//, appType === "Manuals" ? "product_manual/" : "faq/");
                                                            const path = getCitationFilePath(directory);
                                                            window.open(path, "_blank", `width=${window.innerWidth},height=${window.innerHeight}`);
                                                        }}
                                                        title={i18next.t("supportingContent.openinNewWindow")}
                                                    >
                                                        {i18next.t("supportingContent.openinNewWindow")}
                                                    </DefaultButton>
                                                </div>
                                            </div>
                                        )}
                                    </PivotItem>
                                );
                            })}
                            {supportingContent?.length === 0 && (
                                <PivotItem key={0} headerText={`No Content`} itemKey={(1).toString()} itemIcon="PageRemove">
                                    <Label>{i18next.t("supportingContent.noSupportingContent")}</Label>
                                </PivotItem>
                            )}
                        </Pivot>
                        {activeTab && (
                            <Text
                                className={styles.supportingContentItemText}
                                style={{
                                    whiteSpace: "pre-line",
                                    overflowY: "auto",
                                    maxHeight: window.innerHeight - 270,
                                    maxWidth: window.innerWidth - 250,
                                    marginTop: "10px"
                                }}
                            >
                                {selectedContent}
                            </Text>
                        )}
                    </div>
                </ul>
            </>
        );
    }
};
