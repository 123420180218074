export default {
    control: {
        fontSize: 14,
        fontWeight: "normal",
        border: "none",
        boxShadow: "none",
        // height: "70px",
        overflowY: "auto",
        resize: "none"
    },
    "&multiLine": {
        control: {
            // minHeight: 63,
            border: "none",
            maxHeight: window.innerHeight < 1000 ? "22vh" : "30vh",
            overflowY: "auto"
        },
        highlighter: {
            padding: 9,
            border: "1px solid transaparent"
        },
        input: {
            padding: 9,
            border: "none",
            outline: "none",
            boxShadow: "none",
            // height: "70px",
            overflowY: "auto"
        }
    },
    "&singleLine": {
        display: "inline-block",
        width: 180,

        highlighter: {
            padding: 1,
            border: "none"
        },
        input: {
            padding: 1,
            border: "none",
            outline: "none",
            boxShadow: "none",
            // height: "70px",
            overflowY: "auto"
        }
    },
    suggestions: {
        width: "40%",
        overflow: "hidden",
        border: "1px solid rgba(0, 0, 0, 0.3)", // New border for the container
        borderRadius: "10px",
        list: {
            backgroundColor: "#fff",
            border: "1px solid rgba(0, 0, 0, 0.15",
            borderRadius: "10px"
        },
        item: {
            fontSize: window.innerWidth < 1000 ? 10 : 14,
            padding: calculatePadding(),
            borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
            "&focused": {
                backgroundColor: "#d3d3d3",
                color: "black"
            }
        }
    }
};

function calculatePadding() {
    if (window.outerWidth === 800 && window.outerHeight === 600) {
        return "5px 10px";
    } else {
        return "15px";
    }
}
