import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { jwtToken, userInfo } from "./authConfig";
import "./index.css";
import "./i18n/i18n";
import Layout from "./pages/layout/Layout";
import Chat from "./pages/chat/Chat";
import Feedback from "./pages/chat/Feedbacks";
import Report from "./pages/report/Report";
import Settings from "./pages/Settings/Settings";
import Updates from "./pages/updates/Update";
import TermsAndConditions from "./pages/Settings/TermsAndConditions";
import GuideLines from "./pages/Settings/GuideLines";
import { jwtDecode } from "jwt-decode";
import Terms from "./pages/Settings/Terms";
import store from "./Store";
import { Provider } from "react-redux";
import styled, { ThemeProvider } from "styled-components";
import { isValidAppType } from "./api";

const theme = {
    fontFamily: `"BIZ UDPGothic", -apple-system, BlinkMacSystemFont, sans-serif !important`,
    fontColor: "#5A5A5A !important"
};

const AppThemeContainer = styled.div`
    font-family: ${props => props.theme.fontFamily};
    color: ${props => props.theme.fontColor};
`;

function App() {
    const [option, setOption] = useState<boolean>(false);
    const [startTourByIcon, setStartTourByIcon] = useState<boolean>(false);

    useEffect(() => {
        sessionStorage.setItem("SelectedAppType", isValidAppType(userInfo?.apptype) ? userInfo.apptype : "FAQ");
    }, []);

    const onChatClick = () => {
        setOption(true);
    };

    const startTour = () => {
        setStartTourByIcon(true);
    };

    const stopTour = () => {
        setStartTourByIcon(false);
    };

    const onChatClear = () => {
        setOption(false);
    };

    const [userDetail, setUserDetail] = useState({
        terms_accepted: userInfo.terms_accepted
    });

    const onAcceptTerms = () => {
        setUserDetail({ ...userInfo, terms_accepted: "accepted" });
    };

    type TokenValues = {
        isInchargeOfAiWorks: string;
        user_name: string;
    };

    const tokenValues = jwtDecode(jwtToken || "") as TokenValues;
    const isAdmin = tokenValues["isInchargeOfAiWorks"] === "True";

    return (
        <Router>
            <div>
                <Routes>
                    {userDetail.terms_accepted !== "accepted" ? (
                        <>
                            {/* <Route path="/" element={<Home />} /> */}
                            <Route path="/" element={<TermsAndConditions onAccept={onAcceptTerms} />} />
                            <Route path="/assistant/terms" element={<TermsAndConditions onAccept={onAcceptTerms} />} />
                            <Route path="*" element={<Navigate to="/" />} />
                        </>
                    ) : (
                        <>
                            <Route path="/" element={<Navigate to="/assistant/chat" />} />
                            <Route path="/assistant/*" element={<Layout callback={onChatClick} startTour={startTour} />}>
                                <Route
                                    path="chat"
                                    element={<Chat executeClear={option} callback={onChatClear} startTourByIcon={startTourByIcon} startTour={stopTour} />}
                                />
                                {/* <Route
                                    path="qa"
                                    element={
                                        <React.Suspense fallback={<div>Loading...</div>}>
                                            <OneShot />
                                        </React.Suspense>
                                    }
                                /> */}
                                {isAdmin && (
                                    <>
                                        <Route path="feedbacks" element={<Feedback />} />
                                        <Route path="reports" element={<Report />} />
                                        <Route path="settings" element={<Settings />} />
                                    </>
                                )}
                                <Route path="*" element={<Navigate to="/assistant/chat" />} />
                            </Route>
                            <Route path="/assistant/updates" element={<Updates />} />
                            <Route path="/assistant/terms" element={<Terms />} />
                            <Route path="/assistant/guide" element={<GuideLines />} />
                            <Route path="/*" element={<Navigate to="/assistant/chat" />} />
                        </>
                    )}
                </Routes>
            </div>
        </Router>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <AppThemeContainer>
                    <App />
                </AppThemeContainer>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>
);
