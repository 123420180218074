import { Tooltip } from "@fluentui/react-components";
import { LockShield28Filled } from "@fluentui/react-icons";
import i18next from "../../i18n/i18n";
import { useState } from "react";
import { DefaultButton, mergeStyleSets, IconButton, Popup, Layer, Overlay, FocusTrapZone } from "@fluentui/react";
import { Trans } from "react-i18next";

export const LockIcon = () => {
    const [showWarning, setShowWarning] = useState<boolean>(false);

    const popupStyles = mergeStyleSets({
        root: {
            background: "rgba(0, 0, 0, 0.2)",
            bottom: "0",
            left: "0",
            position: "fixed",
            right: "0",
            top: "0"
        },
        content: {
            background: "white",
            left: "50%",
            maxWidth: "1000px",
            padding: "0 0em 2em",
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)"
        }
    });
    return (
        <>
            <Tooltip
                content={{
                    children: i18next.t("chat.Private"),
                    style: {
                        backgroundColor: "white"
                    }
                }}
                relationship="label"
            >
                <LockShield28Filled primaryFill={"rgba(115, 118, 225, 1)"} aria-hidden="true" aria-label="Lock logo" onClick={() => setShowWarning(true)} />
            </Tooltip>
            {showWarning && (
                <Layer>
                    <Popup className={popupStyles.root} role="dialog" aria-modal="true">
                        <Overlay />
                        <FocusTrapZone>
                            <div role="document" className={popupStyles.content}>
                                <div>
                                    <IconButton style={{ color: "red" }} iconProps={{ iconName: "WarningSolid" }} />
                                    <IconButton
                                        style={{ color: "green", float: "right" }}
                                        iconProps={{ iconName: "Cancel" }}
                                        onClick={() => setShowWarning(false)}
                                    />
                                    <span style={{ padding: "5px", fontSize: "15px", fontWeight: "bold" }}>
                                        <Trans i18nKey="chat.warning1" />
                                    </span>
                                    <span style={{ padding: "5px", fontSize: "15px" }}>
                                        <Trans i18nKey="chat.warning2" />
                                    </span>
                                    <span style={{ padding: "5px", fontSize: "15px" }}>
                                        <Trans i18nKey="chat.warning3" />
                                    </span>
                                    <span style={{ padding: "5px", fontSize: "15px" }}>
                                        <Trans i18nKey="chat.warning4" />
                                    </span>
                                </div>
                                <DefaultButton
                                    style={{ borderRadius: "10px", marginLeft: "40%", padding: "5px", top: "10px" }}
                                    onClick={() => setShowWarning(false)}
                                >
                                    {i18next.t("chat.ok")}
                                </DefaultButton>
                            </div>
                        </FocusTrapZone>
                    </Popup>
                </Layer>
            )}
        </>
    );
};
