import { DirectionalHint, IconButton, TooltipHost } from "@fluentui/react";
import styles from "./UserChatMessage.module.css";
import React, { useEffect, useState } from "react";
import { ChatAppResponse, copySharedChatApi, deleteFollowupConversation, AppType, QuestionDetails } from "../../api";
import i18next from "i18next";
import { v4 as uuidv4 } from "uuid";
import { userInfo } from "../../authConfig";
import { ContentCopy } from "@mui/icons-material";
import { Alert, Snackbar } from "@mui/material";

interface Props {
    message: string;
    onSend?: (question: string, appType: string, index: number | undefined) => void;
    conversation?: { userName: string; groupId: string; title: string; conversationDate: any }[];
    index?: number;
    answers?: [user: QuestionDetails, response: { FAQ?: ChatAppResponse; Manuals?: ChatAppResponse }][];
    streamedAnswers?: [user: QuestionDetails, response: { FAQ?: ChatAppResponse; Manuals?: ChatAppResponse }][];
    onEditClick?: (checked: boolean) => void;
    IsOnEdit?: boolean;
    isChatShared?: boolean;
    isAdminUser?: boolean;
    selectedQuestion?: boolean;
    applyRestriction?: boolean;
    reference?: any;
    responseGenerationActive?: boolean;
    selectedAppType: AppType;
    templateId: string | undefined;
    questionContent: string;
    sourcePage: string;
}

interface QuestionInfoToolTipWithCopyOptionProps {
    questionContextTextValue: string;
}

export const UserChatMessage = ({
    onSend,
    message,
    conversation,
    index,
    answers,
    streamedAnswers,
    onEditClick,
    IsOnEdit,
    isChatShared,
    isAdminUser,
    selectedQuestion,
    applyRestriction,
    reference,
    responseGenerationActive,
    selectedAppType,
    templateId,
    questionContent,
    sourcePage
}: Props) => {
    const [isEdit, setIsEdit] = useState(IsOnEdit);
    const [isHover, setIsHover] = useState(false);
    const [editedMessage, setEditedMessage] = useState<string>(message);
    const [conversationDate, setConversationDate] = useState<object>({});
    const [isCheckIconDisabled, setIsCheckIconDisabled] = useState(true);
    const [initialMessage, setInitialMessage] = useState(message);

    useEffect(() => {
        setEditedMessage(message);
    }, [message]);

    useEffect(() => {
        setIsEdit(false);
    }, [applyRestriction]);

    useEffect(() => {
        const messageContentFlag =
            !editedMessage ||
            editedMessage.trim().length === 0 ||
            String(editedMessage).replace(/^\s+/, "").trim() === String(initialMessage).replace(/^\s+/, "").trim();
        if (isEdit && index !== null && index !== undefined && Number.isInteger(index) && answers && answers[index] && answers[index][1]) {
            const appNameValue = answers[index][1]?.[selectedAppType] ? selectedAppType : selectedAppType === "FAQ" ? "Manuals" : "FAQ";
            let productFilterValue: { manual_key: string; faq_key: string } | null = { manual_key: "0", faq_key: "0" };
            productFilterValue = answers[index][1][appNameValue] ? answers[index][1][appNameValue]?.choices[0].context.product_filter : null;
            if (productFilterValue) {
                const disableCheckIconBasedOnProduct =
                    (selectedAppType === "Manuals" && productFilterValue?.manual_key === "") ||
                    (selectedAppType === "FAQ" && productFilterValue?.faq_key === "");
                setIsCheckIconDisabled(messageContentFlag || disableCheckIconBasedOnProduct);
            }
        } else {
            setIsCheckIconDisabled(messageContentFlag);
        }
    }, [editedMessage, initialMessage, index, answers, selectedAppType]);

    const handleEditClick = async (index: number) => {
        if (onEditClick) {
            setIsEdit(true);
            onEditClick(true);

            const conversationDetails = conversation?.[index];
            if (conversationDetails) {
                const { conversationDate, title } = conversationDetails;
                if (!conversationDate[selectedAppType] || conversationDate[selectedAppType] === "NA") {
                    setConversationDate(conversationDate[selectedAppType === "FAQ" ? "Manuals" : "FAQ"]);
                } else {
                    setConversationDate(conversationDate[selectedAppType]);
                }
                setEditedMessage(title);
                setInitialMessage(title);
            }
            setIsCheckIconDisabled(true);
        }
    };

    const copySharedChat = async (sessionvalue: string) => {
        try {
            const newSessionId = sessionStorage.getItem("sessionId");
            await copySharedChatApi(sessionvalue, newSessionId)
                .then(response => {
                    return response.json();
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleCheckClick = async (index: number | undefined) => {
        setIsEdit(false);

        if (isChatShared) {
            const oldSessionID = sessionStorage.getItem("sessionId") ?? "";
            const newSessionId = String(uuidv4());
            sessionStorage.setItem("sessionId", newSessionId);
            await copySharedChat(oldSessionID);
        }

        answers ? answers.splice(index as number) : [];
        streamedAnswers ? streamedAnswers.splice(index as number) : [];

        try {
            await deleteFollowupConversation(conversationDate);
        } catch (error) {
            console.error("Error deleting followup conversation:", error);
        }

        if (onSend) {
            onSend(editedMessage, "FAQ", index);
        }
    };

    const QuestionInfoToolTipWithCopyOption: React.FC<QuestionInfoToolTipWithCopyOptionProps> = ({ questionContextTextValue }) => {
        const [isTooltipVisible, setTooltipVisible] = useState(false);
        const [toastVisible, setToastVisible] = useState(false);

        const handleInfoButtonClick = () => {
            setTooltipVisible(true);
        };

        const copyQuestionToClipBoard = (question: string) => {
            navigator.clipboard.writeText(question).then(() => {
                setToastVisible(true);
                setTimeout(() => {
                    setTooltipVisible(false);
                }, 1500);
            });
        };
        return (
            <TooltipHost
                content={
                    <React.Fragment>
                        <div style={{ float: "right", margin: "8px 16px 0px auto" }}>
                            <IconButton
                                title={i18next.t("chat.templateCopyButton")}
                                style={{ background: "none", color: "#7376e1", width: "25px", height: "25px" }}
                                onClick={e => {
                                    e.preventDefault();
                                    copyQuestionToClipBoard(questionContextTextValue);
                                }}
                            >
                                <ContentCopy sx={{ background: "none", color: "#7376e1", width: "25px", height: "25px" }} />
                            </IconButton>
                        </div>
                        <div
                            className={styles.message}
                            dangerouslySetInnerHTML={{ __html: questionContextTextValue?.replace(/^\s+/, "")?.replace(/\n/g, "<br/>") }}
                        ></div>
                    </React.Fragment>
                }
                directionalHint={DirectionalHint.bottomCenter}
                calloutProps={{
                    isBeakVisible: true,
                    preventDismissOnLostFocus: true,
                    shouldDismissOnWindowFocus: false,
                    onDismiss: e => {
                        e?.preventDefault();
                    }
                }}
                hidden={!isTooltipVisible}
            >
                <IconButton
                    title={i18next.t("chat.templateInfoButton")}
                    style={{ visibility: isHover ? "visible" : "hidden" }}
                    iconProps={{ iconName: "Info" }}
                    onClick={handleInfoButtonClick}
                />
                <Snackbar open={toastVisible} autoHideDuration={3000} onClose={() => setToastVisible(false)}>
                    <Alert onClose={() => setToastVisible(false)} severity="success" variant="filled" sx={{ width: "100%" }}>
                        {i18next.t("chat.templateCopySuccess")}
                    </Alert>
                </Snackbar>
            </TooltipHost>
        );
    };

    return (
        <div className={styles.container}>
            {selectedQuestion && isEdit && IsOnEdit ? (
                <div key={index}>
                    <textarea
                        key={index}
                        value={String(editedMessage).replace(/^\s+/, "")}
                        className={styles.message}
                        style={{
                            fontFamily: "BIZ UDPGothic",
                            fontSize: "16px",
                            width: reference?.current?.offsetWidth ? `${reference?.current?.offsetWidth}px` : "700px",
                            height: "150px",
                            resize: "none"
                        }}
                        onChange={e => {
                            setEditedMessage(e.target.value);
                        }}
                    ></textarea>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <IconButton
                            disabled={isCheckIconDisabled}
                            title={i18next.t("chat.save")}
                            iconProps={{ iconName: "CheckMark" }}
                            onClick={() => {
                                handleCheckClick(index);
                            }}
                        />
                        <span>
                            <IconButton
                                title={i18next.t("chat.cancel")}
                                iconProps={{ iconName: "cancel" }}
                                onClick={() => {
                                    setIsEdit(false);
                                    setEditedMessage(message);
                                    if (onEditClick) onEditClick(false);
                                }}
                            />
                        </span>
                    </div>
                </div>
            ) : (
                <div
                    key={index}
                    onMouseEnter={() => {
                        setIsHover(true);
                    }}
                    onMouseLeave={() => {
                        setIsHover(false);
                    }}
                >
                    <div className={styles.message} dangerouslySetInnerHTML={{ __html: editedMessage?.replace(/^\s+/, "")?.replace(/\n/g, "<br/>") }}></div>
                    {sourcePage === "chat" && (
                        <React.Fragment>
                            <div>
                                <IconButton
                                    title={i18next.t("chat.edit")}
                                    style={{ visibility: isHover ? "visible" : "hidden" }}
                                    iconProps={{ iconName: "Edit" }}
                                    onClick={() => index !== undefined && handleEditClick(index)}
                                    disabled={applyRestriction || responseGenerationActive}
                                />
                                {(isAdminUser || userInfo.isWap === "True" || userInfo.isWap === "true") &&
                                    !!(templateId && templateId !== "") &&
                                    templateId !== "None" && <QuestionInfoToolTipWithCopyOption questionContextTextValue={questionContent} />}
                            </div>
                        </React.Fragment>
                    )}
                    {sourcePage === "feedback" && (
                        <React.Fragment>
                            <div>
                                {(isAdminUser || userInfo.isWap === "True" || userInfo.isWap === "true") &&
                                !!(templateId && templateId !== "") &&
                                templateId !== "None" ? (
                                    <QuestionInfoToolTipWithCopyOption questionContextTextValue={questionContent} />
                                ) : null}
                            </div>
                        </React.Fragment>
                    )}
                </div>
            )}
        </div>
    );
};
