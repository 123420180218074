import { Stack } from "@fluentui/react";
import { Button } from "@fluentui/react-components";
import { styled, Zoom } from "@mui/material";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { useDispatch, useSelector } from "react-redux";
import { userInfo } from "../../authConfig";
import i18next from "../../i18n/i18n";
import store, { RootState } from "../../Store";
import { setSaveTemplateIdInStore } from "../../Store/TemplateContents";
import MentionStyle from "./MentionStyle";
import styles from "./QuestionInput.module.css";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { QuestionInputReducerAction } from "../../Store/QuestionInputReducer";
import { getChatSummaryTitle } from "../../api";

interface Props {
    onSend: (question: string, questionContaninsTemplate: boolean) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    templateContent?: any;
    selectedTemplateContentId?: string;
    onClearTemplateContent?: () => void;
    disableTextarea: boolean;
    productIndex: { manual_key: string; faq_key: string };
    isAdmin: boolean;
    isEdited: boolean;
    setIsEdited: (isEdited: boolean) => void;
    clearChatTriggered?: boolean | undefined;
    setClearChatTriggered?: (isEdited: boolean) => void;
    chatContentCleared?: boolean | undefined;
    setChatContentCleared?: (chatContentCleared: boolean) => void;
    selectedAppType: string;
    changingTemplateContent?: boolean;
    setChangingTemplateContent?: (changingTemplateContentFlag: boolean) => void;
    isCitationActive: boolean;
    isHistoryPageInitialLoading?: boolean;
    setIsHistoryPageInitialLoading?: (isHistoryPageInitialLoadingFlag: boolean) => void;
    handleTemplateContentsBySuggestions?: (contents: string, tempId: string) => void;
    isNewChatThreadInitialLoading?: boolean;
    setIsNewChatThreadInitialLoading?: (isNewChatThreadInitialLoadingFlag: boolean) => void;
    answers?: {}[];
}

export interface QuestionInputRef {
    clearTemplateContentFromQuestionInput: () => void;
    getCurrentQuestionInputValue: () => string;
}

export const QuestionInput = forwardRef<QuestionInputRef, Props>(
    (
        {
            onSend,
            disabled,
            placeholder,
            clearOnSend,
            templateContent,
            selectedTemplateContentId,
            onClearTemplateContent,
            disableTextarea,
            productIndex,
            isAdmin,
            isEdited,
            setIsEdited,
            clearChatTriggered,
            setClearChatTriggered,
            chatContentCleared,
            setChatContentCleared,
            selectedAppType,
            changingTemplateContent,
            setChangingTemplateContent,
            isCitationActive,
            isHistoryPageInitialLoading,
            setIsHistoryPageInitialLoading,
            handleTemplateContentsBySuggestions,
            isNewChatThreadInitialLoading,
            setIsNewChatThreadInitialLoading,
            answers
        }: Props,
        ref
    ) => {
        const [question, setQuestion] = useState<string>("");

        const templateContents = useSelector((store: RootState) => store.templateContents.templateContents);
        const templateTitles = useSelector((store: RootState) => store.templateContents.templateTitles);
        const userDefaultContent = useSelector((store: RootState) => store.templateContents.userDefaultContent);
        const systemDefaultContent = useSelector((store: RootState) => store.templateContents.systemDefaultContent);
        const [defaultContentState, setDefaultContentState] = useState<string>(userDefaultContent ?? systemDefaultContent ?? "");
        const selectedProduct = useSelector((store: RootState) => store.templateContents.selectedProduct);
        const mentionsInputRef = useRef<HTMLTextAreaElement | null>(null);
        const [questionContainsTemplate, setQuestionContainsTemplate] = useState<boolean>(false);
        const [sendQuestionDisabled, setSendQuestionDisabled] = useState<boolean>(true);
        const [difference, setDifference] = useState("");

        let title: any = templateContents.find((content: any) => content.product);

        let allOptionsTemplateId = userInfo?.["userFavourite"]?.["ALL"];
        const allOptionsUserDefault: any = templateContents?.filter((content: any) => content.id === allOptionsTemplateId);

        const dispatch = useDispatch();
        const templateAppliedFlagValue = useSelector((store: RootState) => store.questionInput.templateAppliedFlag);

        useEffect(() => {
            if (isHistoryPageInitialLoading) {
                setQuestion("");
                setDefaultContentState("");
            } else {
                // history page load done & some operations like product change or a new question is already asked
                //  or a template is applied after history page initial load.

                // or the page is a new Chat conversation page.
                const userFavouriteContentForSelectedProduct = userInfo["userFavourite"]?.[selectedProduct];
                if (
                    !!(selectedTemplateContentId && selectedTemplateContentId !== "") &&
                    !changingTemplateContent &&
                    !isEdited &&
                    userFavouriteContentForSelectedProduct &&
                    userFavouriteContentForSelectedProduct === selectedTemplateContentId
                ) {
                    const templateValue = getTemplatePromptTextValueOnPageLoad(templateContent, selectedTemplateContentId);
                    setDefaultContentState(templateValue);
                    setTimeout(() => {
                        dispatch(QuestionInputReducerAction.updateTemplateAppliedFlag(true));
                    }, 100);
                    return;
                }
                if (userFavouriteContentForSelectedProduct === null || userFavouriteContentForSelectedProduct === undefined) {
                    if (userInfo["userFavourite"]?.["ALL"] !== null && !changingTemplateContent) {
                        const allProductDefaultTemplate = getSystemDefaultTemplateForALLProduct();
                        const allProductDefaultTemplatePrompt = getTemplatePromptTextValue(
                            !!(allProductDefaultTemplate && allProductDefaultTemplate !== "") ? allProductDefaultTemplate?.template[0] : ""
                        );
                        setDefaultContentState(allProductDefaultTemplatePrompt);
                        dispatch(
                            QuestionInputReducerAction.updateTemplateAppliedFlag(!!(allProductDefaultTemplatePrompt && allProductDefaultTemplatePrompt !== ""))
                        );
                        return;
                    }
                    setDefaultContentState("");
                } else {
                    if (clearChatTriggered || !chatContentCleared) {
                        const adminQuestionInput = userInfo["userFavourite"]?.hasOwnProperty(selectedProduct)
                            ? userDefaultContent === "" || userDefaultContent === undefined
                                ? title.product !== selectedProduct
                                    ? userInfo["userFavourite"][selectedProduct] !== "" && userInfo["userFavourite"][selectedProduct] !== "None"
                                        ? getTemplatePromptTextValueOnPageLoad(title.template[0], title.id)
                                        : userInfo["userFavourite"][selectedProduct] === "None"
                                          ? ""
                                          : getSystemDefaultTemplatePromptText(false)
                                    : validateConditionsAndApplyUserTemplate()
                                : userDefaultContent === ""
                                  ? getSystemDefaultTemplatePromptText(false)
                                  : validateConditionsAndApplyUserTemplate()
                            : userInfo["userFavourite"]?.["ALL"] !== null
                              ? title.product === selectedProduct
                                  ? getTemplatePromptTextValueOnPageLoad(allOptionsUserDefault[0]?.template[0], allOptionsUserDefault[0]?.id)
                                  : getSystemDefaultTemplatePromptText(false)
                              : "";

                        const nonAdminQuestionInput = userInfo["userFavourite"]?.hasOwnProperty(selectedProduct)
                            ? userDefaultContent === "" || userDefaultContent === undefined
                                ? title.product !== selectedProduct
                                    ? userInfo["userFavourite"][selectedProduct] !== "" && userInfo["userFavourite"][selectedProduct] !== "None"
                                        ? getTemplatePromptTextValueOnPageLoad(title.template[0], title.id)
                                        : userInfo["userFavourite"][selectedProduct] === "None"
                                          ? ""
                                          : getSystemDefaultTemplatePromptText(false)
                                    : validateConditionsAndApplyUserTemplate()
                                : userDefaultContent === ""
                                  ? getSystemDefaultTemplatePromptText(false)
                                  : validateConditionsAndApplyUserTemplate()
                            : userInfo["userFavourite"]?.["ALL"] !== null
                              ? title.product === selectedProduct
                                  ? getTemplatePromptTextValueOnPageLoad(allOptionsUserDefault[0]?.template[0], allOptionsUserDefault[0]?.id)
                                  : getSystemDefaultTemplatePromptText(false)
                              : "";
                        const defaultContentStateValue = isAdmin
                            ? chatContentCleared
                                ? ""
                                : adminQuestionInput
                            : chatContentCleared
                              ? ""
                              : nonAdminQuestionInput;
                        setDefaultContentState(defaultContentStateValue);
                        if (!chatContentCleared) {
                            const userTemplateDataExists = userInfo["userFavourite"]?.hasOwnProperty(selectedProduct);
                            const systemDefaultData = getSystemDefaultTemplate();
                            if (userTemplateDataExists && userInfo["userFavourite"]?.[selectedProduct] === "None") {
                                dispatch(QuestionInputReducerAction.updateTemplateAppliedFlag(false));
                            } else if ((userTemplateDataExists && userInfo["userFavourite"]?.[selectedProduct] !== "None") || systemDefaultData) {
                                dispatch(QuestionInputReducerAction.updateTemplateAppliedFlag(true));
                            } else {
                                dispatch(QuestionInputReducerAction.updateTemplateAppliedFlag(!!(defaultContentStateValue && defaultContentStateValue !== "")));
                            }
                        }
                    }
                }
            }
        }, [userDefaultContent, systemDefaultContent, userInfo["userFavourite"]?.[selectedProduct], allOptionsUserDefault]);

        useEffect(() => {
            if (!isEdited) {
                const templateValue = getTemplatePromptTextValue(templateContent);
                setQuestion(templateValue);
                if (templateValue) {
                    setDefaultContentState(templateValue);
                    setQuestion(templateValue);
                }
                if (!clearChatTriggered) {
                    setDefaultContentState("");
                    setQuestion("");
                } else {
                    return;
                }
            }
        }, [templateContent, isEdited, clearChatTriggered]);

        useEffect(() => {
            if (templateAppliedFlagValue) {
                const questionContainsTemplateFlag = checkIfquestionContainsTemplate();
                setQuestionContainsTemplate(questionContainsTemplateFlag);
            } else {
                setQuestionContainsTemplate(false);
                dispatch(QuestionInputReducerAction.updateTemplateAppliedFlag(false));
            }
        }, [templateContent, isEdited, clearChatTriggered, defaultContentState, question]);

        useEffect(() => {
            const sendQuestionDisabledFlag =
                disabled ||
                (!question?.trim() && !defaultContentState?.trim()) ||
                (selectedAppType === "Manuals" && productIndex?.manual_key === "") ||
                (selectedAppType === "FAQ" && productIndex?.faq_key === "");

            if (templateAppliedFlagValue && !!(templateContent && templateContent !== "" && selectedTemplateContentId && selectedTemplateContentId !== "")) {
                const templatePromptText: string = getTemplatePromptTextValue(templateContent).trim();
                const currentQuestionInputTextValue: string = defaultContentState || (isEdited ? question : getTemplatePromptTextValue(templateContent)) || "";
                let questionInputTemplatePromptEdited = JSON.stringify(templatePromptText.trim()) !== JSON.stringify(currentQuestionInputTextValue.trim());
                if (!questionInputTemplatePromptEdited) {
                    questionInputTemplatePromptEdited = templateContent?.isSystemTemplate === false;
                }
                setSendQuestionDisabled(sendQuestionDisabledFlag || !questionInputTemplatePromptEdited);
            } else {
                setSendQuestionDisabled(sendQuestionDisabledFlag);
            }
        }, [userInfo?.apptype, templateContent, defaultContentState, question, disabled, templateAppliedFlagValue]);

        useImperativeHandle(ref, () => ({
            clearTemplateContentFromQuestionInput: () => clearInput(true),
            getCurrentQuestionInputValue: () => defaultContentState || (isEdited ? question : getTemplatePromptTextValue(templateContent)) || ""
        }));

        useEffect(() => {
            if (mentionsInputRef.current) {
                mentionsInputRef.current.focus();
                const length = mentionsInputRef.current.value.length;
                mentionsInputRef.current.setSelectionRange(length, length);
            }
        }, [templateContent, defaultContentState, answers]);

        const formattedTemplateTitles = templateTitles.slice(0, 5).map(title => ({
            id: title,
            display: title
        }));

        const sendQuestion = () => {
            if (disabled || (!question.trim() && !defaultContentState?.trim())) {
                return;
            }

            if ((selectedAppType === "Manuals" && productIndex?.manual_key === "") || (selectedAppType === "FAQ" && productIndex?.faq_key === "")) {
                dispatch(QuestionInputReducerAction.updateTemplateAppliedFlag(false));
                onSend("invalid_selection", false);
                return;
            }
            let filteredQuestion = question.match(/@\[(.*?)\]/)?.[1];
            dispatch(QuestionInputReducerAction.updateTemplateAppliedFlag(false));
            const questionInputTextValue = filteredQuestion ? filteredQuestion : question === "" ? defaultContentState : question;
            onSend(questionInputTextValue, templateAppliedFlagValue ?? questionContainsTemplate);
            if (clearOnSend) {
                setQuestion("");
                setIsEdited(false);
                setDefaultContentState("");
                setChangingTemplateContent && setChangingTemplateContent(false);
                setChatContentCleared?.(true);
                if (mentionsInputRef.current) {
                    mentionsInputRef.current.value = "";
                }
                if (setClearChatTriggered) {
                    setClearChatTriggered(false);
                }
            }
        };

        const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
            if (sendQuestionDisabled) {
                return;
            }
            if (ev.key === "Enter" && !ev.shiftKey) {
                ev.preventDefault();
                sendQuestion();
                setIsEdited(false);
                setDefaultContentState("");
            }
        };

        const clearInput = (isParentCallBack: boolean) => {
            setQuestion("");
            setDefaultContentState("");
            const questionContainsTemplateFlag = checkIfquestionContainsTemplate();
            if (templateAppliedFlagValue && questionContainsTemplateFlag) {
                // template applied but all prompt loaded is cleared by user.
                if (isParentCallBack) {
                    // indicates the content cleared using template clear btn.
                    dispatch(QuestionInputReducerAction.updateTemplateAppliedFlag(false));
                    setQuestionContainsTemplate(false);
                    setChangingTemplateContent?.(false);
                    setIsEdited(false);
                } else {
                    setChangingTemplateContent?.(true);
                    setIsEdited(true);
                }
                setChatContentCleared?.(true);
            } else {
                // empty value in question input & template not used.
                setIsEdited(false);
                setChatContentCleared?.(true);
                setSaveTemplateIdInStore("");
                setQuestionContainsTemplate(false);
                if (!templateAppliedFlagValue && onClearTemplateContent) {
                    onClearTemplateContent();
                }
            }
            setIsHistoryPageInitialLoading?.(false);
            setIsNewChatThreadInitialLoading?.(false);
        };

        const onQuestionChange = (newValue?: string) => {
            if (!newValue) {
                clearInput(false);
            } else if (newValue.length <= 1000) {
                const questionContainsTemplateFlag = checkIfquestionContainsTemplate();
                if (templateAppliedFlagValue && questionContainsTemplateFlag && setChangingTemplateContent) {
                    setChangingTemplateContent(true);
                }
                setQuestion(newValue);
                setIsEdited(true);
                setDefaultContentState("");
                setChatContentCleared?.(true);
                setIsHistoryPageInitialLoading?.(false);
                setIsNewChatThreadInitialLoading?.(false);
            }
        };

        const handleMentionSelect = (_id: string | number, display: string) => {
            let previewPrompt: any = templateContents.find((content: any) => content.template[0].title.includes(display));
            const templatePromptValue = getTemplatePromptTextValue(previewPrompt.template[0]);
            setQuestion(templatePromptValue);
            handleTemplateContentsBySuggestions?.(previewPrompt.template[0], previewPrompt.id);
        };

        const renderMentionSuggestion = (entry: any, search: string, highlightedDisplay: React.ReactNode) => {
            const content: any = templateContents.find((template: any) => template.template[0].title === entry.display);
            const templateValue = getTemplatePromptTextValue(content?.template[0]);
            const previewPrompt = !!(templateValue && templateValue !== "") ? templateValue : "No preview available";
            const formattedPreviewPrompt = previewPrompt.replace(/\n/g, "<br />");
            return (
                <LightTooltip
                    content={previewPrompt}
                    title={<span dangerouslySetInnerHTML={{ __html: formattedPreviewPrompt }} />}
                    followCursor
                    TransitionComponent={Zoom}
                    placement="right-end"
                >
                    <div>{highlightedDisplay}</div>
                </LightTooltip>
            );
        };

        const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: theme.palette.common.white,
                color: "rgba(0, 0, 0, 0.87)",
                boxShadow: theme.shadows[1],
                fontSize: 11
            }
        }));

        const checkIfquestionContainsTemplate = (): boolean => {
            let questionContainsTemplateFlag = false;
            const inputTextValue = (
                !!(defaultContentState && defaultContentState !== "") ? defaultContentState : isEdited ? question : getTemplatePromptTextValue(templateContent)
            ).trim();
            const templateValue = String(getTemplatePromptTextValue(templateContent)).trim();
            const userDefaultTemplateValue = String(getUserDefaultContentTemplatePromptText(true)).trim();
            const systemDefaultTemplateValue = String(getSystemDefaultTemplatePromptText(true)).trim();
            if (!!(inputTextValue && inputTextValue !== "")) {
                if (templateValue) {
                    questionContainsTemplateFlag = !!(templateContent && templateContent !== "")
                        ? checkIfQuestionIsSubSetOfTemplatePrompt(templateValue, inputTextValue)
                        : false;
                } else if (userDefaultTemplateValue) {
                    questionContainsTemplateFlag = !!(userDefaultTemplateValue && userDefaultTemplateValue !== "")
                        ? checkIfQuestionIsSubSetOfTemplatePrompt(userDefaultTemplateValue, inputTextValue)
                        : false;
                } else {
                    const allProductDefaultTemplate = getSystemDefaultTemplateForALLProduct();
                    const allProductDefaultTemplatePrompt = getTemplatePromptTextValue(
                        !!(allProductDefaultTemplate && allProductDefaultTemplate !== "") ? allProductDefaultTemplate?.template[0] : ""
                    );
                    const systemTemplateValue =
                        userInfo["userFavourite"]?.[selectedProduct] && !!(systemDefaultTemplateValue && systemDefaultTemplateValue !== "")
                            ? systemDefaultTemplateValue
                            : userInfo["userFavourite"]?.["ALL"] !== null && !!(allProductDefaultTemplatePrompt && allProductDefaultTemplatePrompt !== "")
                              ? allProductDefaultTemplatePrompt
                              : "";
                    questionContainsTemplateFlag = !!(systemTemplateValue && systemTemplateValue !== "")
                        ? checkIfQuestionIsSubSetOfTemplatePrompt(systemTemplateValue, inputTextValue)
                        : false;
                }
            } else {
                // value can be empty only when the input is cleared.
                questionContainsTemplateFlag = selectedTemplateContentId ? selectedTemplateContentId !== "None" : false;
            }
            return questionContainsTemplateFlag;
        };

        const checkIfQuestionIsSubSetOfTemplatePrompt = (templatePromptText: string, question: string): boolean => {
            const questionWords = question
                .split(" ")
                .map(word => word.trim())
                .filter(word => word !== "");
            const containsWordFromQuestion = questionWords.some(word => templatePromptText.includes(word));
            return containsWordFromQuestion ? containsWordFromQuestion : selectedTemplateContentId ? selectedTemplateContentId !== "None" : false;
        };

        const getTemplatePromptTextValue = (templateContentData: any): string => {
            if (!!(templateContentData && templateContentData !== "")) {
                const templatePreviewPrompt: string = templateContentData?.previewPrompt;
                const templatePromptInstructions: string = templateContentData?.promptInstructions ?? "";
                const templatePrompt = templatePreviewPrompt.replaceAll(templatePromptInstructions, "").replace(/[ \t]+$/gm, "");
                return templatePrompt;
            }
            return "";
        };

        const getTemplatePromptTextValueOnPageLoad = (templateContentData: any, templateId: string): string => {
            if (!!(templateContentData && templateContentData !== "")) {
                const templatePrompt = getTemplatePromptTextValue(templateContentData);
                if (isNewChatThreadInitialLoading && !templateAppliedFlagValue) {
                    handleTemplateContentsBySuggestions?.(templateContentData, templateId);
                }
                return templatePrompt;
            }
            return "";
        };

        const getSystemDefaultTemplateForALLProduct = (): any => {
            const filteredTemplateValue =
                templateContents
                    .filter((tempContent: any) => tempContent.product && tempContent.product === "ALL")
                    .filter((AllContent: any) => AllContent.template?.[0]?.isDefault === true)?.[0] || "";
            return filteredTemplateValue;
        };

        const getSystemDefaultTemplate = (): any => {
            const filteredTemplateValue =
                selectedProduct === "ALL"
                    ? templateContents
                          .filter((tempContent: any) => tempContent.product && tempContent.product === selectedProduct)
                          .filter((AllContent: any) => AllContent.template?.[0]?.isDefault === true)?.[0]
                    : templateContents.filter((tempContent: any) => tempContent.template?.[0].isDefault === true).map((tempContent: any) => tempContent)[0] ||
                      "";
            return filteredTemplateValue;
        };

        const getSystemDefaultTemplatePromptText = (questionContainsTemplateCheckMethodCall: boolean): string => {
            const filteredTemplateValue: any = getSystemDefaultTemplate();
            const systemDefaultTemplateValue = !!(filteredTemplateValue && filteredTemplateValue !== "")
                ? getTemplatePromptTextValue(filteredTemplateValue?.template[0])
                : "";
            if (
                isNewChatThreadInitialLoading &&
                !!(filteredTemplateValue && filteredTemplateValue !== "") &&
                !questionContainsTemplateCheckMethodCall &&
                !templateAppliedFlagValue
            ) {
                handleTemplateContentsBySuggestions?.(filteredTemplateValue?.template[0], filteredTemplateValue.id);
            }
            return String(userInfo["userFavourite"][selectedProduct] === "" ? systemDefaultTemplateValue : "").replace(/[ \t]+$/gm, "");
        };

        const getUserDefaultContentTemplate = (): any => {
            const selectedProductKey = selectedProduct !== "" ? selectedProduct : "";
            const selectedProductId = userInfo.userFavourite?.[selectedProductKey] ?? "";
            const filteredTemplateValue =
                templateContents
                    .filter((tempContent: any) => tempContent.id === selectedProductId)
                    .map((tempContent: any) => {
                        return tempContent;
                    })[0] || "";
            return filteredTemplateValue;
        };

        const getUserDefaultContentTemplatePromptText = (questionContainsTemplateCheckMethodCall: boolean): string => {
            const filteredTemplateValue: any = getUserDefaultContentTemplate();
            const userDefaultTemplateValue = !!(filteredTemplateValue && filteredTemplateValue !== "")
                ? getTemplatePromptTextValue(filteredTemplateValue?.template[0])
                : "";
            if (
                isNewChatThreadInitialLoading &&
                !!(filteredTemplateValue && filteredTemplateValue !== "") &&
                !questionContainsTemplateCheckMethodCall &&
                !templateAppliedFlagValue
            ) {
                handleTemplateContentsBySuggestions?.(filteredTemplateValue?.template[0], filteredTemplateValue.id);
            }
            return String(userDefaultTemplateValue).replace(/[ \t]+$/gm, "");
        };

        const validateConditionsAndApplyUserTemplate = () => {
            return userInfo["userFavourite"][selectedProduct] !== "" && userInfo["userFavourite"][selectedProduct] !== "None"
                ? !!(selectedTemplateContentId && selectedTemplateContentId !== "")
                    ? userInfo["userFavourite"][selectedProduct] === selectedTemplateContentId
                        ? getTemplatePromptTextValueOnPageLoad(templateContent, selectedTemplateContentId)
                        : getTemplatePromptTextValueOnPageLoad(templateContent, selectedTemplateContentId)
                    : getUserDefaultContentTemplatePromptText(false)
                : userInfo["userFavourite"][selectedProduct] === "None"
                  ? ""
                  : getSystemDefaultTemplatePromptText(false);
        };

        return (
            <>
                <div style={{ display: "flex", width: "100%", alignItems: "flex-end" }}>
                    <Stack horizontal style={{ width: "100%", padding: "0px 15px", background: disableTextarea ? "#F2F2F2" : "#fff", borderRadius: "10px" }}>
                        <div style={{ width: "100%", height: "100%", position: "relative" }}>
                            <MentionsInput
                                inputRef={mentionsInputRef}
                                id="question-input"
                                className={styles.questionInputTextArea}
                                placeholder={placeholder}
                                value={defaultContentState || (isEdited ? question : templateContent?.prompt) || ""}
                                onChange={e => onQuestionChange(e.target.value)}
                                onKeyDown={onEnterPress}
                                disabled={disableTextarea}
                                autoFocus
                                style={MentionStyle}
                                forceSuggestionsAboveCursor={true}
                                allowSuggestionsAboveCursor={true}
                            >
                                <Mention trigger="/" data={formattedTemplateTitles} onAdd={handleMentionSelect} renderSuggestion={renderMentionSuggestion} />
                            </MentionsInput>
                            {!sendQuestionDisabled && (
                                <LightTooltip title={i18next.t("questionInput.tooltip")} placement="top">
                                    <Button
                                        id="sendButton"
                                        size="large"
                                        icon={
                                            <ArrowCircleRightIcon
                                                sx={{
                                                    color: "white",
                                                    backgroundColor: "#6a6ee6",
                                                    borderRadius: "50%",
                                                    width: isCitationActive ? "40px" : "70px",
                                                    height: isCitationActive ? "40px" : "70px"
                                                }}
                                            />
                                        }
                                        disabled={sendQuestionDisabled}
                                        onClick={sendQuestion}
                                        style={{
                                            position: "absolute",
                                            zIndex: 1000,
                                            right: "30px",
                                            bottom: "25px",
                                            height: "70px",
                                            width: "90px",
                                            minWidth: "40px",
                                            maxWidth: "120px"
                                        }}
                                    />
                                </LightTooltip>
                            )}
                        </div>
                    </Stack>
                </div>
            </>
        );
    }
);
