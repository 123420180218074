import { DefaultButton, Stack, Text } from "@fluentui/react";
import { makeStyles, shorthands, Tab, Tooltip } from "@fluentui/react-components";
import { ArrowSyncRegular, History32Filled } from "@fluentui/react-icons";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Box, Button, Divider, Drawer, Skeleton, styled } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { saveUserDetails, SessionInfo, YearData } from "../../api";
import { userInfo } from "../../authConfig";
import i18next from "../../i18n/i18n";
import CreateIcon from "@mui/icons-material/Create";
initializeIcons(undefined, { disableWarnings: true });
interface Props {
    nextDate: string;
    sourcePage: string;
    isLoading: boolean;
    currentIndex: string;
    lastSessionRef?: any;
    lastActiveIndexRef?: any;
    lastActiveSessionRef?: any;
    todaySessionsRef: SessionInfo[];
    last7SessionsRef: SessionInfo[];
    last30SessionsRef: SessionInfo[];
    monthlySessions: YearData[];
    loadChatData: (session_id: string) => void;
    setCurrentIndex: (session_id: string) => void;
    loadSessionsData: () => void;
    isOnSave?: boolean;
    clearChat: () => void;
    isSessionLoading: boolean;
    isAdmin: boolean;
}

const monthNames = {
    Jan: "January",
    Feb: "February",
    Mar: "March",
    Apr: "April",
    May: "May",
    Jun: "June",
    Jul: "July",
    Aug: "August",
    Sep: "September",
    Oct: "October",
    Nov: "November",
    Dec: "December"
};

const useStyles = makeStyles({
    root: {
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        ...shorthands.padding("10px", "20px"),
        rowGap: "10px",
        paddingTop: "0px",
        paddingBottom: "0px",
        paddingLeft: "30px",
        paddingRight: "20px"
    },
    tabStyle: {
        color: "white",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        lineHeight: 2,
        fontSize: "15px",
        cursor: "pointer",
        ":active": {
            fontWeight: "bold"
        },
        ":selected": {
            fontWeight: "bold"
        },
        ":hover": {
            fontWeight: "bold"
        },
        ":target": {
            fontWeight: "bold"
        }
    },
    year: { minHeight: "40px !important", fontSize: "25px" },
    month: { minHeight: "40px !important", fontSize: "20px" }
});

const groupSessionsByYearAndMonth = (data: YearData[]): any => {
    const grouped: any = {};

    data?.forEach(yearData => {
        const { year, sessions } = yearData;

        if (!grouped[year]) {
            grouped[year] = {};
        }

        sessions?.forEach(session => {
            let { monthName, records } = session;
            monthName = getTranslatedMonth(monthName);
            if (!grouped[year][monthName]) {
                grouped[year][monthName] = [];
            }
            records?.forEach((record: any) => {
                const isDuplicate = grouped[year][monthName].some(
                    (existingRecord: any) => existingRecord.sessionId === record.sessionId && existingRecord.conversation_date === record.conversation_date
                );

                if (!isDuplicate) {
                    grouped[year][monthName].push(record);
                }
            });
        });
    });
    return grouped;
};

const getTranslatedMonth = (month: string) => {
    switch (month) {
        case monthNames.Jan:
            return i18next.t("monthNames.Jan");
        case monthNames.Feb:
            return i18next.t("monthNames.Feb");
        case monthNames.Mar:
            return i18next.t("monthNames.Mar");
        case monthNames.Apr:
            return i18next.t("monthNames.Apr");
        case monthNames.May:
            return i18next.t("monthNames.May");
        case monthNames.Jun:
            return i18next.t("monthNames.Jun");
        case monthNames.Jul:
            return i18next.t("monthNames.Jul");
        case monthNames.Aug:
            return i18next.t("monthNames.Aug");
        case monthNames.Sep:
            return i18next.t("monthNames.Sep");
        case monthNames.Oct:
            return i18next.t("monthNames.Oct");
        case monthNames.Nov:
            return i18next.t("monthNames.Nov");
        case monthNames.Dec:
            return i18next.t("monthNames.Dec");
        default:
            return month;
    }
};

export const Sidebar = (props: Props) => {
    const tabstyles = useStyles();
    const [showGroupedSessions, setShowGroupedSessions] = useState(false);
    const sidebarRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [monthGroupedSessions, setMonthGroupedSessions] = useState<any>();
    const [isSidebarOpen, setIsSidebarOpen] = useState(userInfo?.isSidebarOpen ? (userInfo?.isSidebarOpen === "true" ? true : false) : true);
    const calculateDrawerWidth = () => {
        const viewportWidth = window.innerWidth;
        return viewportWidth <= 1400 ? "40vh" : "30vh";
    };

    const DrawerHeader = styled("div")(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: "flex-start"
    }));

    const handleOnClickOfSessionId = (item: SessionInfo, activeSession: any) => {
        const urlParams = new URLSearchParams();
        urlParams.append("SessionId", item.session_id ?? "");
        history.replaceState({}, "", "?" + urlParams.toString());
        props.setCurrentIndex(item.session_id ?? "");
        props.loadChatData(item.session_id ?? "");
        if (props.sourcePage === "chat") {
            props.lastActiveIndexRef.current = item.group;
            props.lastSessionRef.current = item;
            props.lastActiveSessionRef.current = activeSession;
            sessionStorage.setItem("sessionId", item.session_id ?? "");
        }
    };

    useMemo(() => {
        const results = groupSessionsByYearAndMonth([...props.monthlySessions]);
        setMonthGroupedSessions(results);
    }, [props.todaySessionsRef, props.last7SessionsRef, props.last30SessionsRef, props.monthlySessions]);

    useEffect(() => {
        if (window.location.pathname === "/assistant/chat") {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        } else {
            setIsLoading(false);
        }
    }, [props.todaySessionsRef, props.last7SessionsRef, props.last30SessionsRef, props.monthlySessions]);

    const loadMoreSessions = () => {
        props.loadSessionsData();
        setIsLoading(true);
        setShowGroupedSessions(true);
    };

    const EmptySkeletons = () => {
        return (
            <div className={tabstyles.root}>
                <Box sx={{ width: "100%" }}>
                    <Skeleton animation="wave" sx={{ width: "100%" }} />
                    <Skeleton animation="wave" sx={{ width: "90%" }} />
                    <Skeleton animation="wave" sx={{ width: "80%" }} />
                    <Skeleton animation="wave" sx={{ width: "70%" }} />
                </Box>
            </div>
        );
    };

    const handleDrawerOpen = () => {
        const newisSidebarOpen = !isSidebarOpen;
        setIsSidebarOpen(newisSidebarOpen);
        userInfo.isSidebarOpen = newisSidebarOpen.toString();
        saveUserSettings({ isSidebarOpen: newisSidebarOpen.toString() });
    };

    const saveUserSettings = async (settings: object, reloadNeeded?: boolean) => {
        try {
            await saveUserDetails(settings)
                .then(response => {
                    return response.json();
                })
                .then(() => {
                    if (reloadNeeded) {
                        window.location.reload();
                    }
                })
                .catch(error => {
                    console.error("Error saving user data:", error);
                });
        } catch (error) {
            console.error("Error saving user data:", error);
        }
    };

    const iconButtonStyles = {
        width: "100%",
        height: "40px"
    };

    const iconStyles = {
        background: "none",
        width: "40px",
        height: "40px",
        color: "#5A5A5A"
    };

    const toggleButtonContainer = {
        display: "flex",
        justifyContent: "center",
        border: "2px solid rgba(0,0,0,.1)",
        borderRadius: "1.5rem",
        padding: "2px",
        margin: "0px 8px 8px 8px",
        backgroundColor: "white",
        cursor: "pointer"
    };

    const textStyles = {
        padding: "0.25rem 0.5rem",
        fontSize: "16px",
        fontWeight: "bold",
        width: "150px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        color: "#5A5A5A"
    };

    return (
        <Drawer
            ref={sidebarRef}
            id="sidebar"
            open={true}
            variant="persistent"
            anchor="left"
            sx={{
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: isSidebarOpen ? calculateDrawerWidth() : "70px",
                    boxSizing: "border-box",
                    background: "#e3e3e3",
                    fontWeight: 650,
                    transition: "all 0.5s",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    height: innerHeight - 55,
                    zIndex: 0
                }
            }}
            ModalProps={{ keepMounted: false }}
        >
            <Box sx={{ position: "sticky", top: 0, background: "#e3e3e3", zIndex: 1, paddingBottom: "8px", margin: isSidebarOpen ? "8px 8px" : "8px 0px" }}>
                {props.sourcePage === "chat" && (
                    <Tooltip
                        content={{
                            children: i18next.t("clearChat.newChat"),
                            style: {
                                backgroundColor: "white"
                            }
                        }}
                        relationship="label"
                    >
                        <div id="historyToggleButton" style={toggleButtonContainer}>
                            <Button onClick={props.clearChat} disabled={props.currentIndex === "" || props.isOnSave} sx={iconButtonStyles}>
                                <CreateIcon sx={iconStyles} />
                                {isSidebarOpen && <Text style={textStyles}>{i18next.t("clearChat.newChat")}</Text>}
                            </Button>
                        </div>
                    </Tooltip>
                )}
                <Tooltip
                    content={{
                        children: i18next.t(isSidebarOpen ? "layout.closeDrawer" : "layout.openDrawer"),
                        style: {
                            backgroundColor: "white"
                        }
                    }}
                    relationship="label"
                >
                    <div id="historyToggleButton" style={toggleButtonContainer}>
                        <Button onClick={handleDrawerOpen} disabled={props.isOnSave} sx={iconButtonStyles}>
                            {isSidebarOpen ? <ArrowBackIosNewIcon sx={iconStyles} /> : <History32Filled style={iconStyles} />}
                            {isSidebarOpen && <Text style={textStyles}>{i18next.t("layout.closeDrawer")}</Text>}
                        </Button>
                    </div>
                </Tooltip>
                <Divider />
            </Box>

            {isSidebarOpen && (
                <Box sx={{ overflowY: "auto", flexGrow: 1 }}>
                    {Number(props.todaySessionsRef.length) === 0 &&
                    Number(props.last7SessionsRef.length) === 0 &&
                    Number(props.last30SessionsRef.length) === 0 &&
                    Object.keys(monthGroupedSessions ?? {}).length === 0 ? (
                        <div
                            style={{
                                boxSizing: "border-box",
                                background: "#e3e3e3",
                                position: "relative",
                                overflowY: "auto",
                                overflowX: "hidden",
                                top: "1px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                height: innerHeight - 200,
                                transition: "all 0.5s",
                                zIndex: 0,
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap"
                            }}
                        >
                            <p>{i18next.t("layout.NoDataAvailable")}</p>
                        </div>
                    ) : (
                        <>
                            {!props.isSessionLoading && props.todaySessionsRef?.length > 0 && Object.keys(props.todaySessionsRef[0] ?? {}).length !== 0 ? (
                                <>
                                    <DrawerHeader>{i18next.t("chat.today")}</DrawerHeader>
                                    <div className={tabstyles.root}>
                                        {props.todaySessionsRef.map((item, index) => (
                                            <Tab
                                                className={tabstyles.tabStyle}
                                                key={item.session_id + "_" + index}
                                                value={item.session_id}
                                                title={
                                                    props.isAdmin || userInfo.isWap === "True" || userInfo.isWap === "true"
                                                        ? item.title
                                                        : item.chat_summary_title
                                                }
                                                onClick={() => handleOnClickOfSessionId(item, props.todaySessionsRef)}
                                                disabled={props.isLoading}
                                            >
                                                <Text
                                                    style={{
                                                        fontWeight: props.currentIndex === item.session_id ? "bold" : "normal",
                                                        fontSize: "medium",
                                                        background:
                                                            props.currentIndex === item.session_id
                                                                ? `linear-gradient(90deg,none,none 84%,transparent 89%,transparent)`
                                                                : "none",
                                                        padding: "4px",
                                                        borderRadius: "10px",
                                                        backgroundColor: props.currentIndex === item.session_id ? "#b4b4b4" : "none"
                                                    }}
                                                >
                                                    {item.chat_summary_title ?? item.title}
                                                </Text>
                                            </Tab>
                                        ))}
                                    </div>
                                </>
                            ) : (
                                isLoading &&
                                window.location.pathname === "/assistant/chat" && (
                                    <Box>
                                        <DrawerHeader>{i18next.t("chat.today")}</DrawerHeader>
                                        <EmptySkeletons />
                                    </Box>
                                )
                            )}

                            {!props.isSessionLoading && props.last7SessionsRef?.length > 0 && Object.keys(props.last7SessionsRef[0] ?? {}).length !== 0 ? (
                                <>
                                    <DrawerHeader>{i18next.t("chat.last7days")}</DrawerHeader>
                                    <div className={tabstyles.root}>
                                        {props.last7SessionsRef.map((item, index) => (
                                            <Tab
                                                className={tabstyles.tabStyle}
                                                key={item.session_id + "_" + index}
                                                value={item.session_id}
                                                title={
                                                    props.isAdmin || userInfo.isWap === "True" || userInfo.isWap === "true"
                                                        ? item.title
                                                        : item.chat_summary_title
                                                }
                                                onClick={() => handleOnClickOfSessionId(item, props.last7SessionsRef)}
                                                disabled={props.isLoading}
                                            >
                                                <Text
                                                    style={{
                                                        fontWeight: props.currentIndex === item.session_id ? "bold" : "normal",
                                                        fontSize: "medium",
                                                        background:
                                                            props.currentIndex === item.session_id
                                                                ? `linear-gradient(90deg,none,none 84%,transparent 89%,transparent)`
                                                                : "none",
                                                        padding: "4px",
                                                        borderRadius: "10px",
                                                        backgroundColor: props.currentIndex === item.session_id ? "#b4b4b4" : "none"
                                                    }}
                                                >
                                                    {item.chat_summary_title ?? item.title}
                                                </Text>
                                            </Tab>
                                        ))}
                                    </div>
                                </>
                            ) : (
                                isLoading &&
                                window.location.pathname === "/assistant/chat" && (
                                    <Box>
                                        <DrawerHeader>{i18next.t("chat.last7days")}</DrawerHeader>
                                        <EmptySkeletons />
                                    </Box>
                                )
                            )}

                            {!props.isSessionLoading && props.last30SessionsRef?.length > 0 && Object.keys(props.last30SessionsRef[0] ?? {}).length !== 0 ? (
                                <>
                                    <DrawerHeader>{i18next.t("chat.last30days")}</DrawerHeader>
                                    <div className={tabstyles.root}>
                                        {props.last30SessionsRef.map((item, index) => (
                                            <Tab
                                                className={tabstyles.tabStyle}
                                                key={item.session_id + "_" + index}
                                                value={item.session_id}
                                                title={
                                                    props.isAdmin || userInfo.isWap === "True" || userInfo.isWap === "true"
                                                        ? item.title
                                                        : item.chat_summary_title
                                                }
                                                onClick={() => handleOnClickOfSessionId(item, props.last30SessionsRef)}
                                                disabled={props.isLoading}
                                            >
                                                <Text
                                                    style={{
                                                        fontWeight: props.currentIndex === item.session_id ? "bold" : "normal",
                                                        fontSize: "medium",
                                                        background:
                                                            props.currentIndex === item.session_id
                                                                ? `linear-gradient(90deg,none,none 84%,transparent 89%,transparent)`
                                                                : "none",
                                                        padding: "4px",
                                                        borderRadius: "10px",
                                                        backgroundColor: props.currentIndex === item.session_id ? "#b4b4b4" : "none"
                                                    }}
                                                >
                                                    {item.chat_summary_title ?? item.title}
                                                </Text>
                                            </Tab>
                                        ))}
                                    </div>
                                </>
                            ) : (
                                isLoading &&
                                window.location.pathname === "/assistant/chat" && (
                                    <Box>
                                        <DrawerHeader>{i18next.t("chat.last30days")}</DrawerHeader>
                                        <EmptySkeletons />
                                    </Box>
                                )
                            )}

                            {((props.sourcePage === "chat" && showGroupedSessions) ||
                                props.sourcePage !== "chat" ||
                                (props.sourcePage === "chat" &&
                                    Object.keys(props.todaySessionsRef[0] ?? {}).length === 0 &&
                                    Object.keys(props.last7SessionsRef[0] ?? {}).length === 0 &&
                                    Object.keys(props.last30SessionsRef[0] ?? {}).length === 0)) &&
                                Object.keys(monthGroupedSessions ?? {}).length > 0 &&
                                (!props.isSessionLoading ? (
                                    <React.Fragment>
                                        {Object.keys(monthGroupedSessions)
                                            ?.sort()
                                            .reverse()
                                            .map((year, index) => (
                                                <div key={`year-${index}`}>
                                                    {year !== new Date().getFullYear().toString() && (
                                                        <>
                                                            <br />
                                                            <DrawerHeader className={tabstyles.year} style={{ textDecoration: "underline" }}>
                                                                {year}
                                                            </DrawerHeader>
                                                        </>
                                                    )}
                                                    {Object.keys(monthGroupedSessions[year])?.map((month, idx) => (
                                                        <div key={`month-${index}-${idx}`}>
                                                            <DrawerHeader className={tabstyles.month}>{month}</DrawerHeader>
                                                            <div className={tabstyles.root}>
                                                                {monthGroupedSessions[year][month]?.map((item: any, index: number) => (
                                                                    <Tab
                                                                        className={tabstyles.tabStyle}
                                                                        key={item.session_id + "_" + index}
                                                                        value={item.session_id}
                                                                        title={
                                                                            props.isAdmin || userInfo.isWap === "True" || userInfo.isWap === "true"
                                                                                ? item.title
                                                                                : item.chat_summary_title
                                                                        }
                                                                        onClick={() => handleOnClickOfSessionId(item, monthGroupedSessions[year][month])}
                                                                        disabled={props.isLoading}
                                                                    >
                                                                        <Text
                                                                            style={{
                                                                                fontWeight: props.currentIndex === item.session_id ? "bold" : "normal",
                                                                                fontSize: "medium"
                                                                            }}
                                                                        >
                                                                            {item.chat_summary_title ?? item.title}
                                                                        </Text>
                                                                    </Tab>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Box sx={{ height: window.innerHeight }}>
                                            <DrawerHeader>{i18next.t("chat.year")}</DrawerHeader>
                                            <div className={tabstyles.root}>
                                                {Array.from({ length: Math.round(window.innerHeight / 28) }, (_, i) => i + 1).map(item => (
                                                    <Tab
                                                        className={tabstyles.tabStyle}
                                                        key={i18next.t("chat.year") + "_" + item}
                                                        value={i18next.t("chat.year") + "_" + item}
                                                        disabled={props.isLoading}
                                                    >
                                                        <Text>
                                                            <Skeleton animation="wave" sx={{ width: calculateDrawerWidth() }} />
                                                        </Text>
                                                    </Tab>
                                                ))}
                                            </div>
                                        </Box>
                                    </React.Fragment>
                                ))}
                            {props.nextDate !== "" && (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        padding: "10px"
                                    }}
                                >
                                    <DefaultButton
                                        style={{
                                            padding: "10px 20px",
                                            backgroundColor: "#7376e1",
                                            color: "white",
                                            border: "none",
                                            borderRadius: "10px",
                                            cursor: "pointer",
                                            width: "auto"
                                        }}
                                        onClick={loadMoreSessions}
                                    >
                                        {isLoading ? (
                                            <CircularProgress style={{ width: "20px", height: "20px", color: "white" }} />
                                        ) : (
                                            <ArrowSyncRegular style={{ marginRight: "5px" }} />
                                        )}
                                    </DefaultButton>
                                </div>
                            )}
                        </>
                    )}
                </Box>
            )}
        </Drawer>
    );
};
