import { useState, useEffect } from "react";
import { PrimaryButton } from "@fluentui/react";
import { makeStyles } from "@fluentui/react-components";
import { loadMetricsDataApi, updateMetricsDataApi, fetchUserDataApi } from "../../api";
import { Bar } from "react-chartjs-2";
import "./Report.css";
import "chart.js/auto";

const useStyles = makeStyles({
    report_container: {
        height: "100%",
        width: "100%",
        alignItems: "center"
    },
    report_layout: {
        position: "relative",
        height: "70%",
        width: "100%",
        maxWidth: "765px",
        display: "flex",
        flexDirection: "row",
        marginLeft: "3%"
    },
    button_container: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignSelf: "center",
        marginLeft: "40%"
    }
});
const Report = () => {
    const [metricsData, setMetricsData] = useState({
        faqTotal: 0,
        manualsTotal: 0,
        faqEmpty: 0,
        manualsEmpty: 0,
        faqPositive: 0,
        manualsPositive: 0,
        faqNegative: 0,
        manualsNegative: 0
    });
    const [isLoading, setIsLoading] = useState(false);

    const faqData = {
        labels: ["Total No.of Conversations", "No Comments", "Positive Comments", "Negative Comments"],
        datasets: [
            {
                label: "FAQ - Conversations Report",
                data: [metricsData.faqTotal, metricsData.faqEmpty, metricsData.faqPositive, metricsData.faqNegative],
                backgroundColor: ["#147ccb", "#5d5f60", "#0fbf2a", "#e80916"],
                hoverBackgroundColor: ["#2c92e0", "#75787a", "#33f150", "#f34752"]
            }
        ]
    };

    const data = {
        labels: ["Total No.of Conversations", "No Comments", "Positive Comments", "Negative Comments"],
        datasets: [
            {
                label: "Manuals -Conversations Report",
                data: [metricsData.manualsTotal, metricsData.manualsEmpty, metricsData.manualsPositive, metricsData.manualsNegative],
                backgroundColor: ["#147ccb", "#5d5f60", "#0fbf2a", "#e80916"],
                hoverBackgroundColor: ["#2c92e0", "#75787a", "#33f150", "#f34752"]
            }
        ]
    };

    const faqOptions = {
        maintainAspectRatio: true,
        responsive: true,
        legend: {
            display: false
        },
        datalabels: {
            display: false
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Types of information"
                }
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: "No. of Messages"
                }
            }
        }
    };

    const options = {
        maintainAspectRatio: true,
        responsive: true,
        legend: {
            display: false
        },
        datalabels: {
            display: false
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Types of information"
                }
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: "No. of Messages"
                }
            }
        }
    };

    const loadMetricsData = async () => {
        setIsLoading(true);
        try {
            await loadMetricsDataApi()
                .then(response => {
                    return response.json();
                })
                .then(result => {
                    setMetricsData(result);
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        setIsLoading(false);
    };

    const fetchUserData = async () => {
        setIsLoading(true);
        try {
            const response = await fetchUserDataApi();
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            const currentDate = new Date();
            const day = currentDate.getDate().toString().padStart(2, "0");
            const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
            const year = currentDate.getFullYear();
            const hours = currentDate.getHours().toString().padStart(2, "0");
            const minutes = currentDate.getMinutes().toString().padStart(2, "0");
            const seconds = currentDate.getSeconds().toString().padStart(2, "0");
            const formattedDatetime = `${day}${month}${year}${hours}${minutes}${seconds}`;
            const filename = `user_data_list_${formattedDatetime}.ods`;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } catch (error) {
            console.error("Error in downloading report:", error);
        }
        setIsLoading(false);
    };

    const Spinner = () => {
        return (
            <div className="full-page-spinner">
                <div className="spinner"></div>
            </div>
        );
    };

    useEffect(() => {
        // history.replaceState({}, "", "/#/reports");
        loadMetricsData();
    }, []);

    const tabstyles = useStyles();

    return (
        <div className={tabstyles.report_container} style={{ overflow: "auto", marginTop: "6%" }}>
            {isLoading && <Spinner />}
            <div className={tabstyles.report_layout} style={{ gap: "3%", width: "47%" }}>
                <Bar data={data} options={options} />
                <Bar data={faqData} options={faqOptions} />
            </div>
            <div className={tabstyles.button_container} style={{ gap: "5%" }}>
                <div>
                    <PrimaryButton
                        style={{ padding: "5px 5px 5px 5px", width: "120px", borderRadius: "10px", height: "34px", position: "relative", bottom: "0px" }}
                        text="Sync Count"
                        onClick={async () => {
                            setIsLoading(true);
                            await updateMetricsDataApi();
                            await loadMetricsData();
                        }}
                        disabled={isLoading}
                        title={"Please click the button to Sync Data"}
                    />
                </div>
                <div>
                    <PrimaryButton
                        style={{ padding: "5px 5px 5px 5px", width: "120px", borderRadius: "10px", height: "34px", position: "relative", bottom: "0px" }}
                        text="Download Data"
                        onClick={async () => {
                            await fetchUserData();
                        }}
                        disabled={isLoading}
                        title={"Please click the button to Download Data"}
                    />
                </div>
            </div>
        </div>
    );
};

export default Report;
